import React from 'react';
import { useHistory } from 'react-router-dom';

const BackButton = () => {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <button onClick={handleGoBack} id='buttonReturn'><svg
    width="25"
    height="25"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="20"
      cy="20"
      r="20"
      fill="#00856F" />
    <path
      d="M20 27V13"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M13 20L20 13L27 20"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg></button>
  );
};

export default BackButton;